import "lazysizes";
import "picturefill";
// var $ = require('jQuery');

//アコーディオン
$(".topService__item__head").each(function () {
  $(this).on("click", function () {
    $("+.topService__item__text", this).slideToggle();
    $(this).toggleClass("is-active");
    return false;
  });
});

//ヘッダーメニュー
$("#header__spBtn, .header__spMenu__item").on("click", function () {
  $("html").toggleClass("scroll-prevent");
  if ($("#header__spBtn").hasClass("is-active")) {
    $("#header__spBtn").removeClass("is-active");
    $("#header__spMenu").removeClass("is-active");
  } else {
    $("#header__spBtn").addClass("is-active");
    $("#header__spMenu").addClass("is-active");
  }
});

// プライバシー
$(function () {
  $(".wpcf7-list-item-label").replaceWith(function () {
    $(this).replaceWith(
      `<span></span><p><a class="wpcf7-list-item-label" href="${path}/privacy" target="_blank">` +
        $(this).text() +
        "</a>について同意する"
    );
  });
});
